export const Slogan = ({ color='currentColor', className='', isTitle=false, strokeWidth='0', width='1', height='1'}) => {
    return (
        <>
            {
                isTitle ?   <svg className={className} version="1.0" xmlns="http://www.w3.org/2000/svg" height={height + 'em'} width={width + 'em'} viewBox="0 0 1300 312" preserveAspectRatio="xMidYMid meet">
                                <g>
                                    <g transform="translate(0,285) scale(0.1,-0.1)" fill={color} stroke="none">
                                        <path d="M2193 2218 c-39 -65 -153 -304 -149 -314 3 -8 16 -19 28 -24 13 -6 38 -19 56 -30 41 -25 53 -18 115 62 23 31 68 87 100 125 31 39 57 77 57 86 0 8 -22 26 -48 40 -27 14 -65 35 -85 46 -43 24 -63 26 -74 9z"/>
                                        <path d="M177 2083 c-9 -8 -7 -1127 2 -1248 l6 -90 100 -2 c56 0 105 3 113 9 9 8 12 62 12 207 l0 197 318 18 c174 10 346 16 382 15 l65 -4 3 -199 c1 -118 7 -205 13 -212 13 -16 198 -7 215 10 13 13 0 1264 -13 1277 -4 4 -57 9 -118 11 l-110 3 0 -335 0 -335 -60 -3 c-33 -1 -156 -9 -273 -17 -230 -16 -418 -19 -426 -7 -2 4 -5 160 -7 347 -1 187 -4 346 -7 353 -4 13 -202 18 -215 5z"/>
                                        <path d="M2065 1773 c-27 -8 -63 -21 -78 -29 -16 -8 -33 -14 -38 -14 -23 0 -190 -111 -247 -164 l-63 -59 29 -31 c16 -17 41 -48 57 -68 15 -21 35 -38 45 -38 9 0 48 26 86 58 61 51 117 89 193 131 33 18 125 41 165 41 44 0 95 -22 128 -55 25 -25 72 -109 73 -130 0 -6 -69 -12 -175 -16 -186 -7 -234 -16 -336 -66 -65 -32 -151 -110 -178 -163 -10 -19 -24 -59 -32 -88 -47 -178 30 -322 205 -382 53 -19 77 -21 169 -17 121 5 185 21 284 72 38 19 74 35 80 35 7 0 8 -17 4 -50 -6 -46 -5 -50 16 -54 13 -2 55 -10 93 -18 90 -17 103 -14 109 25 43 296 47 453 17 607 -23 121 -35 158 -80 249 -47 95 -119 169 -205 210 -61 29 -74 31 -169 30 -58 -1 -123 -7 -152 -16z m375 -569 c11 -11 15 -35 14 -85 0 -56 -4 -72 -20 -84 -31 -24 -222 -115 -241 -115 -10 -1 -29 -7 -43 -15 -16 -9 -54 -14 -103 -15 -74 0 -80 2 -108 29 -25 26 -29 37 -29 86 0 72 20 107 85 149 94 60 176 77 335 69 64 -3 100 -9 110 -19z"/>
                                        <path d="M4767 1784 c-4 -4 -7 -36 -7 -70 0 -35 -3 -65 -8 -68 -4 -2 -18 6 -30 19 -36 38 -152 86 -250 106 -155 30 -340 -49 -445 -190 -54 -73 -62 -88 -89 -163 -18 -52 -21 -84 -21 -189 1 -161 24 -235 110 -351 73 -97 170 -154 311 -184 71 -15 91 -16 135 -5 29 6 70 15 92 20 42 8 139 53 166 77 9 8 19 11 22 7 21 -20 -6 -149 -48 -230 -21 -43 -97 -108 -154 -134 -63 -29 -201 -31 -263 -4 -58 26 -115 71 -134 106 -17 33 -49 38 -92 15 -15 -8 -49 -23 -76 -33 -26 -10 -50 -23 -53 -30 -6 -16 42 -111 70 -138 62 -59 124 -97 209 -127 88 -31 305 -33 388 -3 86 31 160 78 221 142 63 64 99 124 131 216 21 60 21 77 22 637 l1 575 -101 3 c-55 1 -103 0 -107 -4z m-197 -260 c77 -38 125 -87 160 -160 63 -134 26 -295 -90 -389 -130 -105 -328 -95 -416 22 -65 86 -69 99 -69 228 0 113 2 123 29 170 65 117 125 153 248 154 74 1 94 -3 138 -25z"/>
                                        <path d="M5550 1740 c-86 -28 -150 -67 -211 -130 -77 -78 -110 -140 -140 -263 -24 -99 -24 -184 0 -285 28 -118 66 -187 146 -267 102 -102 204 -145 345 -145 230 1 416 146 491 385 25 79 26 258 3 330 -52 159 -138 267 -272 343 -91 51 -257 66 -362 32z m286 -226 c100 -81 129 -152 127 -314 0 -101 -3 -122 -26 -171 -31 -68 -68 -113 -121 -146 -34 -22 -52 -26 -121 -26 -47 -1 -90 5 -104 12 -34 19 -111 91 -111 105 0 7 -5 17 -12 24 -25 25 -48 127 -48 212 0 143 54 259 151 322 36 23 63 27 148 22 67 -4 78 -7 117 -40z"/>
                                        <path d="M6576 1752 c-10 -2 -55 -9 -100 -15 l-81 -12 3 -160 c2 -88 7 -315 10 -505 l7 -345 97 0 c74 0 101 4 111 15 11 12 13 57 8 243 -3 127 -2 236 3 245 35 67 128 191 170 228 54 47 75 53 100 29 33 -34 39 -88 43 -405 2 -173 6 -326 9 -338 5 -23 7 -24 99 -20 52 1 99 8 105 14 12 12 15 436 4 480 -6 24 4 42 83 150 60 82 118 134 149 134 12 0 30 -12 40 -27 17 -26 19 -58 24 -388 l5 -360 109 1 c80 1 111 5 118 15 13 18 1 489 -16 674 -14 146 -24 178 -73 230 -47 51 -84 65 -164 65 -93 0 -139 -22 -230 -107 l-67 -64 -11 34 c-15 43 -59 93 -105 118 -50 26 -158 26 -219 -1 -53 -24 -145 -103 -158 -137 -18 -48 -29 -11 -29 97 0 112 -4 121 -44 112z"/>
                                        <path d="M8260 1744 c-139 -46 -278 -162 -316 -264 -3 -8 -11 -28 -19 -45 -22 -50 -45 -169 -45 -232 0 -32 7 -85 15 -116 8 -32 15 -66 16 -75 0 -9 11 -37 24 -62 13 -25 28 -54 34 -66 18 -39 121 -142 164 -164 23 -11 49 -25 57 -29 77 -43 242 -54 340 -23 85 26 161 74 218 137 110 120 152 231 152 400 0 171 -45 288 -155 401 -100 104 -219 154 -360 153 -44 0 -100 -7 -125 -15z m277 -230 c100 -82 128 -152 127 -314 -1 -101 -4 -122 -27 -171 -31 -68 -68 -113 -121 -146 -34 -22 -52 -26 -121 -26 -66 -1 -88 3 -116 20 -101 63 -159 183 -159 329 0 139 47 246 134 310 51 36 83 44 170 38 61 -4 75 -9 113 -40z"/>
                                        <path d="M2915 1721 c-5 -9 5 -390 23 -856 l7 -180 94 2 c143 3 134 -13 132 249 l-2 221 74 99 c84 112 160 188 211 209 69 29 124 -16 146 -121 8 -32 17 -64 22 -70 11 -15 187 45 202 68 7 13 5 37 -9 95 -31 125 -63 177 -142 229 -52 34 -60 37 -133 37 -72 0 -84 -3 -147 -39 -67 -37 -193 -151 -193 -174 0 -6 -8 -10 -17 -8 -16 3 -19 19 -23 123 l-5 120 -117 3 c-69 1 -119 -1 -123 -7z"/>
                                    </g>
                                    <g transform="translate(775,45)">
                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width={width + 'em'} height={height + 'em'} viewBox="0 0 300.000000 300.000000" preserveAspectRatio="xMidYMid meet">
                                            <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)" fill={color} stroke="none">
                                                <path d="M670 2335 c-9 -11 -8 -69 4 -267 17 -267 28 -519 50 -1071 10 -234 18 -342 26 -352 18 -21 641 -22 659 -1 7 8 14 82 18 187 l6 174 18 -39 c9 -22 23 -43 31 -47 8 -4 40 4 78 21 l65 29 6 -87 c4 -48 7 -119 8 -159 1 -61 4 -74 20 -83 12 -6 126 -10 300 -10 241 0 282 2 295 16 9 8 16 18 16 22 2 167 63 1483 75 1601 3 33 1 59 -6 67 -18 22 -741 21 -759 -1 -10 -12 -8 -92 9 -397 12 -211 21 -429 21 -485 l0 -103 -80 0 -80 0 0 58 c0 31 9 249 21 484 17 344 19 431 9 443 -18 22 -791 23 -810 0z m800 -12 c0 -5 -11 -228 -25 -498 -14 -269 -25 -494 -25 -499 0 -8 218 -3 224 5 2 3 -26 521 -48 897 l-6 102 370 0 370 0 -5 -22 c-2 -13 -11 -153 -20 -313 -8 -159 -17 -333 -20 -385 -8 -129 -35 -832 -35 -897 l0 -53 -295 0 -295 0 -1 123 c-1 67 -4 145 -7 174 l-7 52 -74 -35 c-40 -19 -76 -34 -80 -34 -9 0 -71 155 -73 182 -3 42 -15 -205 -17 -334 l-1 -128 -320 0 c-309 0 -320 1 -320 19 0 169 -61 1453 -76 1594 l-6 57 396 0 c218 0 396 -3 396 -7z"/>
                                            </g>
                                        </svg>
                                    </g>
                                </g>
                            </svg>
                        :   <svg className={className} xmlns="http://www.w3.org/2000/svg" height={height + 'em'} width={width + 'em'}>
                                <g>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width={width + 'em'} height={height + 'em'} viewBox="0 0 300.000000 300.000000" preserveAspectRatio="xMidYMid meet">
                                        <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)" fill={color} stroke="none">
                                            <path d="M670 2335 c-9 -11 -8 -69 4 -267 17 -267 28 -519 50 -1071 10 -234 18 -342 26 -352 18 -21 641 -22 659 -1 7 8 14 82 18 187 l6 174 18 -39 c9 -22 23 -43 31 -47 8 -4 40 4 78 21 l65 29 6 -87 c4 -48 7 -119 8 -159 1 -61 4 -74 20 -83 12 -6 126 -10 300 -10 241 0 282 2 295 16 9 8 16 18 16 22 2 167 63 1483 75 1601 3 33 1 59 -6 67 -18 22 -741 21 -759 -1 -10 -12 -8 -92 9 -397 12 -211 21 -429 21 -485 l0 -103 -80 0 -80 0 0 58 c0 31 9 249 21 484 17 344 19 431 9 443 -18 22 -791 23 -810 0z m800 -12 c0 -5 -11 -228 -25 -498 -14 -269 -25 -494 -25 -499 0 -8 218 -3 224 5 2 3 -26 521 -48 897 l-6 102 370 0 370 0 -5 -22 c-2 -13 -11 -153 -20 -313 -8 -159 -17 -333 -20 -385 -8 -129 -35 -832 -35 -897 l0 -53 -295 0 -295 0 -1 123 c-1 67 -4 145 -7 174 l-7 52 -74 -35 c-40 -19 -76 -34 -80 -34 -9 0 -71 155 -73 182 -3 42 -15 -205 -17 -334 l-1 -128 -320 0 c-309 0 -320 1 -320 19 0 169 -61 1453 -76 1594 l-6 57 396 0 c218 0 396 -3 396 -7z"/>
                                        </g>
                                    </svg>
                                </g>
                            </svg>
            }
        </>
    )
}

export default Slogan;