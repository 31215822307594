// import './index.scss';
import { Suspense, lazy } from "react";
import { createRoot } from 'react-dom/client';
import { Slogan } from '../src/components/icons/logo/Slogan.js';

console.info('Index | Start');

const App = lazy(() => import('./App.js'));

const root = createRoot( document.getElementById('root') );

root.render(
  <Suspense delayMs={0} fallback={ <div style={{ alignItems:'center', display:'flex', height:'100vh', justifyContent:'center', width:'100%', animation:'splash 0.75s linear infinite' }}><Slogan color={'#5285c5'} height={3.5} width={7.25} /></div> }>
    <App Logo={Slogan} />
  </Suspense>
);
console.info('Index | End');
